import React from "react";
import Home from "./Home";
import SEO from "../components/seo";
import Layout from "../components/layout";

const IndexPage = () => (
    <Layout>
        <SEO title="Docaposte Agile-IT" keywords={[`Docaposte`, `Docapost`, `agility`, `agilité`, `Poste`]} />

        <Home />
    </Layout>
);

export default IndexPage;
