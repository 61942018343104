import React from "react";
import styled from "styled-components";
import ImageFront from "../components/ImageFront";
import ValeurCompetences from "../components/valeurCompetences";
import Competences from "../components/competencesHome";
import Wrapper from "../components/wrapper";
import Equipe from "../components/equipeHome";
import Realisation from "../components/realisationHome";
import { frontImageText, content } from "../utils/homeContent.json";

const Home = () => (
    <React.Fragment>
        <ImageFront
            surtitle={frontImageText.surtitle}
            title={frontImageText.title}
            span={frontImageText.span}
            subtext={frontImageText.subtext}
        />
        <Wrapper>
            <Content>
                <h1>{content.titreRang1}</h1>
                <ValeurCompetences />
            </Content>
        </Wrapper>
        <Competences />
        <Equipe />
        <Realisation />
    </React.Fragment>
);
const Content = styled.section`
    position: relative;
    padding-bottom: 40px;
`;
export default Home;
