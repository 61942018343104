import React from "react";
import styled from "styled-components";
import variable from "./variable";
import coin from "../images/motif-coin.png";
import esprit from "../images/phrase2/startup.png";
import amelioration from "../images/phrase2/amelioration.png";
import flexibilite from "../images/phrase2/flexibilite.png";
import veille from "../images/phrase2/veille-techno.png";
import projet from "../images/phrase2/projet.png";
import humain from "../images/phrase2/humain.png";
import { content } from "../utils/homeContent.json";

const Image = [
    {
        src: esprit,
        alt: "esprit-startup",
    },
    {
        src: flexibilite,
        alt: "flexibilité",
    },
    {
        src: veille,
        alt: "veille technologique",
    },
    {
        src: projet,
        alt: "maîtrise des projets",
    },
    {
        src: humain,
        alt: "l'humain, le collectif",
    },
    {
        src: amelioration,
        alt: "amélioration continue",
    },
];

const valeurCompetence = () => {
    let uniquekey = 0;
    const uniqueKey = () => {
        uniquekey += 1;
        return uniquekey;
    };

    return (
        <ValeurCompetence>
            <div className="title-container">
                <h2 className="title-before">{content.titreRang2num1}</h2>
            </div>
            <img src={coin} className="coin" alt="coin" />
            <div className="quote">{content.presentation}</div>
            <div className="container">
                <h3 className="secondary-title">{content.titrerang3}</h3>
                <div className="phrase">
                    {Image.map(ImageItem => {
                        return (
                            <React.Fragment key={uniqueKey()}>
                                <img src={ImageItem.src} alt={ImageItem.alt} />
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </ValeurCompetence>
    );
};

const ValeurCompetence = styled.section`
    .quote {
        background-color: ${variable.lightGrey};
        border-left: 3px solid ${variable.secondaryColor};
        padding: ${variable.marginBase};
        color: ${variable.bodyColor};
    }
    .coin {
        position: absolute;
        right: 0;
        top: 10px;
        width: 90px;
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    .container {
        padding: 0 ${variable.marginBase};
        padding-bottom: 0;
    }
    .phrase {
        max-width: 1000px;
        img {
            max-width: 250px;
            margin-right: ${variable.marginBase};
            margin-top: 10px;
        }
        @media screen and (max-width: 600px) {
            img {
                max-width: 200px;
            }
        }
    }
`;

export default valeurCompetence;
