import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import variable from "./variable";
import Wrapper from "./wrapper";
import cotepro2 from "../images/coteprov2.png";
import { content } from "../utils/homeContent.json";

const realisationHome = () => (
    <Wrapper>
        <div className="title-container">
            <h2 className="title-before">{content.titreRang2num5}</h2>
        </div>
        <Realisation>
            <div className="realisation">
                <img src={cotepro2} alt="cote pro" />
            </div>
            <div className="container-flex">
                <RealisationDescription>
                    <h2 className="mt0 mb10">{content.realisation.title}</h2>
                    <h5 className="mt0 mb0">{content.realisation.subtitle}</h5>
                    <p>{content.realisation.mainText}</p>
                    <p className="subText mb0">Rôle : {content.realisation.role}</p>
                </RealisationDescription>
            </div>
        </Realisation>
        <div className="flex-end pb40">
            <Link to="/Realisation">
                <button type="button">Voir d'autres réalisations</button>
            </Link>
        </div>
    </Wrapper>
);

const Realisation = styled.div`
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
    .container-flex {
        display: flex;
        align-items: flex-start;
        width: 30%;
    }
    .realisation {
        width: 70%;
        img {
            width: 100%;
            max-height: 450px;
            object-fit: cover;
        }
    }
    @media screen and (max-width: 992px) {
        flex-direction: column;
        .realisation {
            width: 100%;
        }
        .container-flex {
            width: 100%;
        }
    }
`;

const RealisationDescription = styled.div`
    background-color: ${variable.lightGrey};
    position: relative;
    z-index: 999;
    padding: 30px;
    border-radius: 25px;
    h2 {
        color: ${variable.primaryColor};
    }
    h5 {
        color: ${variable.secondaryColor};
        font-weight: normal;
    }
    .subText {
        color: ${variable.secondGrey};
    }
`;

export default realisationHome;
