import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import variable from "./variable";
import Wrapper from "./wrapper";
import { content } from "../utils/homeContent.json";

const competenceHome = () => {
    let uniquekey = 0;
    const uniqueKey = () => {
        uniquekey += 1;
        return uniquekey;
    };

    return (
        <Container>
            <Competence>
                <Wrapper>
                    <div className="title-container">
                        <h2 className="title-before">{content.titreRang2num2}</h2>
                    </div>
                    <div className="competence-container">
                        {content.COMPETENCES.map(COMPETENCESItem => {
                            return (
                                <Link to="/Competences" key={uniqueKey()}>
                                    <div className="competence-unit">{COMPETENCESItem}</div>
                                </Link>
                            );
                        })}
                    </div>
                </Wrapper>
            </Competence>
        </Container>
    );
};
const Container = styled.div`
    padding-bottom: 40px;
`;
const Competence = styled.div`
    margin-top: ${variable.marginBase};
    padding-bottom: 40px;
    background-image: radial-gradient(#f5f5f5 33%, transparent 0), radial-gradient(#f5f5f5 33%, transparent 0);
    background-size: 20px 20px;
    background-position: 20px 0 20px 0;

    .competence-unit {
        color: ${variable.primaryColor};
        border: 1px solid ${variable.primaryColor};
        background: #fff;
        border-radius: 15px;
        padding: 5px 10px 5px 10px;
        display: inline-block;
        margin: 10px 15px 0 5px;
    }
    @media screen and (max-width: 600px) {
        padding-bottom: 30px;
        .competence-unit {
            margin-right: 10px;
            padding: 5px 10px 5px 10px;
            margin-top: 10px;
        }
    }
`;

export default competenceHome;
