import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import variable from "./variable";
import Wrapper from "./wrapper";
import team from "../images/team.jpg";
import { content } from "../utils/homeContent.json";
import WrapperImage from "./wrapperImage";

const equipeHome = () => (
    <React.Fragment>
        <Wrapper>
            <div className="title-container">
                <h2 className="title-before">{content.titreRang2num4}</h2>
            </div>
        </Wrapper>
        <Equipe>
            <WrapperImage>
                <img src={team} alt="team" />
            </WrapperImage>
        </Equipe>
        <Wrapper>
            <div className="flex-end pb40">
                <Link to="/Equipe">
                    <button type="button">Voir l'ensemble de l'équipe</button>
                </Link>
            </div>
        </Wrapper>
    </React.Fragment>
);

const Equipe = styled.div`
    background-color: ${variable.lightGrey};
    z-index: -999;
    margin-bottom: ${variable.marginBase};
    height: 500px;
    img {
        width: 100%;
        height: 500px;
        object-fit: cover;
    }
 @media screen and (max-width: 600px) {
    height:330px;
        img {
             height:330px;
            }
        }
    }
`;

export default equipeHome;
